<template>
    <Dialog modal header="Crear baremo" :style="{width: '500px'}">          
        <div class="form-group">
            <label for="nombre">Nombre</label>
            <input type="text" class="form-control" v-model="nombre" id="nombre" placeholder="Nombre"/>
        </div> 
        <div class="form-group">
            <label for="tipo">Tipo</label>
            <select class="form-control" v-model="tipo" id="tipo">
                <option value="cliente">Cliente</option>
                <option value="operario">Operario</option>
                <option value="proveedor">Proveedor</option>
            </select>            
        </div>   
        <div style="display: flex; width: 100%;">
            <div class="form-group" style="width: 100%;">
                <label for="inicio">Fecha inicio</label>
                <input class="form-control" v-model="fecha_inicio" type="date" id="inicio" />
            </div>
            <div  style="margin-left: 15px;width: 100%;"  class="form-group">
                <label for="fin">Fecha fin</label>
                <input class="form-control" v-model="fecha_fin" type="date" id="fin" />
            </div>
        </div>
        <div>
            <button @click="crearBaremo()" style="width: 100%;" class="btn btn-success">
                Guardar
            </button>
        </div> 
    </Dialog>
</template>

<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import Dialog from 'primevue/dialog';
export default {
    props: ['idservicio'],
    components: {
        Dialog,
    },
    data() {
        return {
            nombre:'',
            tipo: 'Cliente',
            fecha_inicio:'',
            fecha_fin:'',
        };
    },
    mounted() {
    },
    methods: {    
        async crearBaremo(){
            if(this.nombre=='' || this.fecha_fin=='' || this.fecha_inicio==''){
                alert('Faltan campos por rellenar');
                return;
            }
            const api = new PwgsApi();
            var fecha_ini = this.parseDate(this.fecha_inicio);
            var fecha_fin = this.parseDate(this.fecha_fin);
            const body = {tipo:this.tipo, nombre: this.nombre, fecha_inicio : fecha_ini, fecha_fin : fecha_fin};
            await api.post('baremos-pwgs', body);
            this.$emit('update:visible');
            this.$emit('baremo-creado');
        },
        parseDate(dateString) {
            const [day, month, year] = dateString.split('-');
            return(`${year}/${month}/${day}`);
        }
    },
};
</script>

<style scoped>

</style>